/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

* {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 14px;
}

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    overflow-y: hidden;
}

.page {
    margin-top: 60px;
    padding: 24px 45px 55px 45px;
    background-color: #f7f9fb;
    overflow-y: auto;
    height: calc(100% - 60px);

    button {
        padding: 5px 30px;
        border-radius: 15.5px;
        background-color: #5303a8;
        color: white;
        border: initial;
        cursor: pointer;

        &.white {
            border: solid 1px #5303a8;
            background: white;
            color: #5303a8;
        }

        img {
            height: 12px;
            margin-right: 7px;
        }
    }

    h1 {
        font-size: 18px;
        font-weight: 600;
        color: #52576a;
        text-transform: uppercase;
        margin: 0;
    }

    h2 {
        color: #52576a;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 24px;

        .count {
            font-size: 18px;
            text-transform: none;
        }
    }
    .title h2 {
        text-transform: uppercase;
    }

    .d-flex {
        display: flex;
    }

    table {
        width: 100%;
        box-shadow: 0 2px 4px 0 #dbe4f9, -5px 0 0 0 #5303a8;
        margin-top: 24px;
        background-color: #ffffff;
        border-spacing: 0;

        tr {
            width: 100%;

            td {
                max-width: 11vw;
                overflow-wrap: break-word;

            }
        }

        th {
            background-color: #ffffff;
            color: #0d1e42;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            white-space: nowrap;
            text-align: left;
            padding: 15px 5px;
            border-bottom: solid 1px rgba(161, 173, 205, 0.5);

            &.center {
                text-align: center;
            }
        }

        tr:nth-child(odd) {
            background-color: rgba(242, 243, 245, 0.7);
        }

        tr:hover {
            background-color: #e1e3e7;
        }

        .selected {
            vertical-align: middle;
        }

        td {
            padding: 9px 5px;
            color: #52576a;

            a {
                color: #52576a;
            }

            &.center {
                text-align: center;
            }


            img {
                cursor: pointer;
                height: 15px;
                width: 15px;
            }

            &.exclamation-td {
                display: flex;

                .exclamation-wrap {
                    width: 16px;
                    margin-left: 5px;

                    .exclamation {
                        vertical-align: text-top;
                    }
                }
            }

            .active-inactive {
                margin: auto 0;
                background-color: #bbb;
                padding: 0 7px;
                border-radius: 30%;
                height: 15px;
                width: 15px;

                &.active {
                    background-color: #67db7a;
                }
            }

            &.link {
                cursor: pointer;
                color: #0596ff;
                text-decoration: underline;
            }

        }

        th:nth-child(1),
        td:nth-child(1) {
            padding-left: 1rem;
        }

        td:nth-child(1) {
            cursor: pointer;
            color: #0596ff;
        }

        td.number {
            padding-left: 2rem;
        }

        td.open-menu {
            position: relative;
        }

        button.action {
            justify-content: center;
            padding: 0 40px;
            margin: 1rem 1rem 1rem 0;
            height: 1.875rem;
            min-width: 120px;
            //  border: 1px solid #5303a8;
            border-radius: 0.9375rem;
            outline: 0;
            font-style: normal;
            font-weight: 400;
            font-size: .875rem;
            line-height: 1.75rem;
            text-align: center;
            cursor: pointer;
            white-space: nowrap;
            color: #fff;
            background: #5303a8;

            &.proces-btn {
                padding: 0 10px;
                border: initial;
                min-width: 105px;

                &.stop {
                    background: red;
                }
            }

            img {
                height: 15px;
                width: 15px;
            }
        }

    }

}

.empty-wrap {
    margin-top: 45px;

    img {
        margin-left: 25%;
        margin-top: 12px;
    }

    h2 {
        font-size: 24px;
        color: #52576a;
        font-weight: normal;
    }

    h3 {
        font-size: 16px;
        font-weight: normal;
        color: #727891;
    }
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-menu-panel {
    min-height: auto !important;

    .mat-menu-content {
        border-top: 0 !important;
    }
}

.mask-popup {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(13, 13, 13, 0.7);
    z-index: 2;
    .content {
        h1 {
            margin: 0 0 20px;
            color: #5303a8;
        }

        width: 62%;
        padding: 27px 23px 20px 25px;
        border-radius: 4px;
        box-shadow: 0 46px 100px 0 rgba(0, 0, 0, 0.15),
        0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #fff;
        position: fixed;
        right: 0;
        left: 0;
        margin: auto;
        top: 37px;
        border-top: 4px solid #7003e1;
        padding: 20px 25px;
        max-width: 750px;
        max-height: 80%;
        overflow-y: auto;

        .wrap-field {
            width: 100%;
            margin-bottom: 20px;
        }

        .wrap-title {
            display: flex;
            align-items: start;
            justify-content: space-between;

            img {
                height: 16px;
                width: 16px;
                cursor: pointer;
            }
        }

        .label {
            font-size: 14px;
            font-weight: 600;
            color: #52576a;
            margin-bottom: 6px;
        }

        input[type='text'],
        input[type='phone'],
        input[type='email'],
        input[type='password'],
        textarea {
            width: 100%;
            border-radius: 2px;
            border: solid 1px #acb2c3;
            height: 40px;
            padding-left: 15px;

            &:focus-visible {
                border: solid 1px #5303a8;
                outline: none;
            }

            &.ng-invalid.ng-touched {
                border-color: red !important;
            }
        }

        textarea {
            height: 90px;
        }

        .d-flex {
            margin-top: 40px;
            flex-direction: row-reverse;

            button.white {
                margin-right: 20px;
            }

        }

        span {
            padding-left: 4px;

            &.error {
                color: red;
                font-style: normal;
                font-size: 12px;
            }

        }

        .wrap-input {
            position: relative;
            width: fit-content;

            &:hover {
                .upload-input-btn {
                    color: #5303a8;
                    border-color: #5303a8;
                }
            }

            input {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }

            .upload-input-btn {
                border-radius: 2px;
                border: solid 1px #acb2c3;
                height: 40px;
                padding: 0 15px;
                display: flex;
                align-items: center;
                color: #727891;

                img {
                    height: 14px;
                    width: 14px;
                    margin-left: 10px;
                }

            }
        }

    }

    ::placeholder {
        color: #727891;
    }
}

::ng-deep {
    .mat-form-field {
        width: 100%;

    }

    .mat-form-field-wrapper {
        width: 100%;
        border-radius: 2px;
        border: solid 1px #acb2c3;
        height: 40px;
        margin-bottom: 25px;
        padding-left: 15px;

    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
        display: none !important;
    }

    .mat-form-field-appearance-legacy .mat-form-field-infix {
        padding: 0 !important;
    }

    .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
        color: #5303a8;
    }

    .mat-primary .mat-pseudo-checkbox-checked {
        background: #5303a8;
    }

}

.custom-spinner {
    flex-direction: column-reverse;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    text-align: center;
    opacity: 0.7;
    background-color: #fff;
    z-index: 99;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #acb2c3;
    border-radius: 5px;
    border: 2px solid #e1e3e7;
}
.toast-container{
.mdclone-toast.ngx-toastr  {
    cursor: default;
    border-style: solid;
    background-color: #ffffff !important;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 11px 15px 15px;
    color: #52576a;
    border: initial;
    border-top: 4px solid;
    box-shadow: 0 0 12px #999999;
    &.info {
        border-color: #5303a8;
    }

    &.success {
        border-color: #03cfa3;
    }

    &.error {
        border-color: #e20343; 
    }
    &.error {
        border-color: #e20343; 
    }
    &.toast-error {
        background-image: url("assets/Icons/failure.png");
        background-size: 16px;
        background-position: left 15px top 25px;
    }
    &.toast-success{
        background-image: url("assets/Icons/success.png");
        background-size: 16px;
        background-position: left 15px top 25px;
    } 
    &.toast-info{
        background-image: url("assets/Icons/info.svg");
        background-size: 16px;
        background-position: left 15px top 25px;
    } 
    .toastr-title{
        color: #0d1e42;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        width: 100%;
        border-bottom: 1px solid rgba(172, 178, 195, 0.5);
        padding-bottom: 12px;
        margin-top: -19px;
        padding-left: 23px;
    }
    .toastr-message{
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #52576a;
    }
     button.toast-close-button{
        position: relative;
        float: right;
        font-size: 23px;
        font-weight: bold;
        color: black;
        width: 100%;
        text-align: right;
        span{
            font-size: 100%;
        }
     }
}
}
